<template>
  <v-app-bar
    v-scroll="onScroll"
    :color="!isScrolling ? 'transparent' : 'coreview'"
    fixed
    flat
    :dark="!isScrolling"
    dense
  >
    <v-slide-x-transition>
      <v-img
        v-if="showLogo"
        :src="require('@/assets/dara_star.svg')"
        class="shrink"
        contain
        height="40"
      />
    </v-slide-x-transition>

    <v-spacer />
    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-btn
        icon
        color="white"
        @click.stop="$emit('drawer')"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </div>
    <div v-else>
      <router-link :scroll="isScrolling" @logout="$emit('logout')" />
    </div>

  </v-app-bar>
</template>

<script>
export default {
  name: 'CoreAppBar',
  components: {
    RouterLink: () => import('@/components/RouterLink')
  },
  data: () => ({
    showLogo: false,
    isScrolling: false
  }),
  methods: {
    onScroll () {
      const offset = window.pageYOffset
      this.isScrolling = offset > 150
      this.showLogo = offset > 160
    }
  }
}
</script>
